
























































/* eslint-disable-next-line */
// @ts-ignore
import { VuePrlxDirective } from 'vue-prlx';
import { Component, Vue, Ref } from 'vue-property-decorator';
import TitleMainDisplay from './Text/TitleMainDisplay.vue';
import TitleDecoration from './Text/TitleDecoration.vue';
import Button from './Button/Button.vue';
import LinkSecondarySmall from './Button/LinkSecondarySmall.vue';
import Logo from '../assets/svg/logo.svg';
import Img01 from '../assets/svg/img01.svg';
import PlusSign from '../assets/svg/plus-sign.svg';
import Numbers from '../assets/svg/icon-numbers.svg';

@Component({
  components: {
    TitleMainDisplay,
    TitleDecoration,
    Button,
    LinkSecondarySmall,
    Logo,
    Img01,
    PlusSign,
    Numbers,
  },
  directives: {
    prlx: VuePrlxDirective,
  },
})
export default class Header extends Vue {
  @Ref('surprised') readonly surprised!: HTMLElement;

  created = () => {
    const loading = document.getElementById('overlay-loading');
    if (!loading) return;
    const parent = loading.parentNode;
    if (parent) parent.removeChild(loading);
  }

  goToBancar = () => {
    window.location.hash = '';
    window.location.hash = 'bancar';
  };

  goToBancanteWorld() {
    this.$router.push({ name: 'Redirect' });
  }

  pauseCat() {
    this.surprised.classList.remove('active');
  }

  unpauseCat() {
    this.surprised.classList.add('active');
  }
}
