












import { Vue, Component, Prop } from 'vue-property-decorator';
import PaymentOptionObject from '../objects/PaymentOptionObject';
import RadioButton from './Button/RadioButton.vue';

@Component({
  components: {
    RadioButton,
  },
})
export default class PaymentOptionButton extends Vue {
    @Prop() private onClick!: Function;

    @Prop() private paymentOptionObject!: PaymentOptionObject;

    @Prop() private selected!: string;

    private publicPath = process.env.BASE_URL;

    public onClickEvent() {
      this.onClick(this.paymentOptionObject.value);
    }
}
