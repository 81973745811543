
























































import { Component, Vue } from 'vue-property-decorator';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import TitleDecoration from './Text/TitleDecoration.vue';
import TitleElement from './Text/TitleElement.vue';
import LabelDecorativo from './Text/LabelDecorativo.vue';
import TestimonialObject from '../objects/TestimonialObject';
import Button from './Button/Button.vue';
import IconFlechaLeft from '../assets/svg/icon-flecha-left.svg';
import IconFlechaRight from '../assets/svg/icon-flecha-right.svg';
import 'swiper/css/swiper.css';

@Component({
  components: {
    Swiper,
    SwiperSlide,
    TitleElement,
    TitleDecoration,
    LabelDecorativo,
    Button,
    IconFlechaLeft,
    IconFlechaRight,
  },
})

export default class Testimonials extends Vue {
    private testimonials: Array<TestimonialObject> = [
      new TestimonialObject(
        '“Me encanta leerlos, escucharlos y verlos. Generan una comunidad muy positiva de la que quiero ser parte. Este tipo de iniciativas me dan esperanza en nuestro futuro como humanidad..”',
        'Sofia Perechodnik - Encuesta permanente de Bancantes',
      ),
      new TestimonialObject(
        '“Porque, además de creer que la divulgación macanuda es fundamental para el desarrollo de sociedades más justas, soberanas y coso. [...] Soy muy sincera si digo que ver gente haciendo y hablando de ciencia con tanto amor me hizo llorar más veces de las que recuerdo. Eso, para mi humilde mirada, es TODO.”',
        'Yesica Zampieri - Encuesta permanente de Bancantes',
      ),
      new TestimonialObject(
        '“Veo a Gato como una herramienta para que la gente pueda acercarse a la ciencia. Lo pienso como un servicio para que la comunidad mejore. Así como pago por el barrido, alumbrado y limpieza, también pago para que la sociedad se contagie de argumentos científicos.”',
        'Pablo Pilotti - Via Mail',
      ),
      new TestimonialObject(
        '“Porque necesitamos de manera urgente la difusión de información confiable, basada en evidencia y datos. Confío en que son un camino para generar un quiebre en la forma en que consumimos información y tomamos decisiones.”',
        'Agustín Sande - Encuesta permanente de Bancantes',
      ),
      new TestimonialObject(
        '“Es mi grano de arena para cambiar el mundo / Todo su contenido me ayudó a tener una visión más clara del mundo. / Porque las ciencias, las artes y las humanidades son lo único que pueden evitar que nos extingamos”',
        'Múltiples bancantes - Encuesta permanente de Bancantes',
      ),
    ];

    $refs!: {
      mySwiper: any;
    }

    private delay = 10000;

    private swiperOption = {
      loop: true,
      autoplay: {
        delay: this.delay,
        disableOnInteraction: false,
      },
      init: false,
    }

    private counter = 0;

    private lastTick = performance.now();

    get slider() {
      return this.$refs.mySwiper.$swiper;
    }

    get progress() {
      return Math.floor((this.counter / this.delay) * 100);
    }

    slide(dir: number) {
      if (dir) this.slider.slideNext();
      else this.slider.slidePrev();
    }

    mounted() {
      this.slider.init();
      requestAnimationFrame(this.counterTick);
      this.slider.on('slideChange', () => {
        this.counter = 0;
      });
    }

    counterTick() {
      const now = performance.now();
      const delta = now - this.lastTick;
      this.lastTick = now;
      this.counter += delta;
      window.requestAnimationFrame(this.counterTick);
    }

    goToBancar = () => {
      window.location.hash = '';
      window.location.hash = 'bancar';
    };
}
