













import { Vue, Component, Prop } from 'vue-property-decorator';
import RadioButtonIcon from './Button/RadioButtonIcon.vue';
import PaymentSelectorObject from '../objects/PaymentSelectorObject';

@Component({
  components: {
    RadioButtonIcon,
  },
})
export default class PaymentMethodButton extends Vue {
    @Prop() private onClick!: Function;

    @Prop() private paymentMethodObject!: PaymentSelectorObject;

    @Prop() private selected!: string;

    onClickEvent() {
      this.onClick(this.paymentMethodObject.name);
    }
}
