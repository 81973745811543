
























































import { Component, Vue } from 'vue-property-decorator';
/* eslint-disable-next-line */
// @ts-ignore
import { VuePrlxDirective } from 'vue-prlx';
import TitleDisplay from './Text/TitleDisplay.vue';
import TextSmall from './Text/TextSmall.vue';
import TextMedium from './Text/TextMedium.vue';
import TitleDecoration from './Text/TitleDecoration.vue';
import LabelDecorativo from './Text/LabelDecorativo.vue';
import IconIBrainYou from '../assets/svg/icon-i-brain-you.svg';
import IconArrow from '../assets/svg/icon-arrow.svg';
import TitleMainDisplay from './Text/TitleMainDisplay.vue';
import Img03 from '../assets/svg/img03.svg';
import WordSlider from './WordSlider.vue';

@Component({
  components: {
    TitleDisplay,
    TextSmall,
    IconIBrainYou,
    TextMedium,
    IconArrow,
    TitleDecoration,
    TitleMainDisplay,
    Img03,
    LabelDecorativo,
    WordSlider,
  },
  directives: {
    prlx: VuePrlxDirective,
  },
})
export default class BancanteWorld extends Vue {

}
