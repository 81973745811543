
























import { JsonConvert } from 'json2typescript';
import { Vue, Component } from 'vue-property-decorator';
import axios from 'axios';
import TitleDisplay from './Text/TitleDisplay.vue';
import TitleElement from './Text/TitleElement.vue';
import TextLarge from './Text/TextLarge.vue';
import Cat from './Cat.vue';
import CatButton from './CatButton.vue';
import Modal from './Modal.vue';
import CatObject from '../objects/CatObject';
import Api from '../helpers/Api';

@Component({
  components: {
    Modal,
    Cat,
    CatButton,
    TitleDisplay,
    TitleElement,
    TextLarge,
  },
})
export default class CatDisplayer extends Vue {
  private catObjects: Array<CatObject> = [];

  private selectedCat!: CatObject;

  private isModalVisible = false;

  created() {
    this.getCats();
  }

  onModalClose() {
    this.isModalVisible = false;
  }

  onCatClick(catData: CatObject) {
    this.selectedCat = catData;
    this.isModalVisible = true;
  }

  async getCats() {
    try {
      const data = await axios.get<Array<string>>(Api.cats);

      this.catObjects = data.data.map(
        (x: string) => new JsonConvert().deserializeObject(JSON.parse(x), CatObject),
      ).reverse();
    } catch (e) {
      console.error(e);
    }
  }
}

