

















import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelDecorativo from './Text/LabelDecorativo.vue';
import TitleElement from './Text/TitleElement.vue';
import TextSmall from './Text/TextSmall.vue';
import CardObject from '../objects/CardObject';

@Component({
  components: {
    LabelDecorativo,
    TitleElement,
    TextSmall,
  },
})
export default class Card extends Vue {
    @Prop() private cardObj!: CardObject;

    private publicPath = process.env.BASE_URL;
}
