export default class TestimonialObject {
  public testimonial = '';

  public author = '';

  constructor(testimonial: string, author: string) {
    this.testimonial = testimonial;
    this.author = author;
  }
}
