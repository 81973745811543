export default class Api {
  public static url = 'https://indiev2.abrecultura.com/';

  // public static url = 'https://phpstack-229668-2595452.cloudwaysapps.com/';

  // public static url = 'http://indie.local/';

  public static mateUrl = 'https://mate.abrecultura.com/';

  public static mateStorage = 'https://abrecultura.s3.amazonaws.com/storage/prod/';

  public static api = `${Api.url}api/`;

  public static mateApi = `${Api.mateUrl}api/`;

  public static cats = `${Api.url}gatos/gatos.json`;

  public static extradata = `${Api.api}extraData/get`;

  public static subscriptionGenerate = `${Api.api}subscription/create`;

  public static subscriptionGet = `${Api.api}subscription/get`;

  public static subscriptionCount = `${Api.api}subscription/count`;

  public static subscriptionResendEmail = `${Api.api}subscription/resendWelcomeEmail`;

  public static sendMontoMail = `${Api.api}subscription/sendMontoMail`;

  public static sendCancelMail = `${Api.api}subscription/sendCancelMail`;

  public static getReferrals = `${Api.api}subscription/getReferrals`;

  public static getStatus = `${Api.api}crawler/checkStatus`;

  public static userDataGet = `${Api.api}UserData/get`;

  public static userDataUpdate = `${Api.api}UserData/update`;

  public static giftGet = `${Api.api}gift/get`;

  public static giftCheckout = `${Api.api}gift/checkout`;

  public static walletGet = `${Api.api}wallet/get`;

  public static walletSave = `${Api.api}wallet/save`;

  public static mateStoreJson = `${Api.mateStorage}79.json`;

  public static mateOrderCheck = `${Api.mateApi}v2/order/check`;

  public static ssoRedirect = `${Api.api}subscription/ssoRedirect`;

  public static mocaGet = `${Api.api}moca/get`;

  public static getUserConsent = `${Api.api}userConsent/getConsent`;

  public static updateUserConsent = `${Api.api}userConsent/updateConsent`;

  public static ssoLinkFulfill = `${Api.api}subscription/ssoLinkFulfill`;

  public static discountGet = `${Api.api}discount/get`;

  public static notificationsGet = `${Api.api}subscription/getNotifications`;

  public static billingGet = `${Api.api}billing/get`;

  public static billGet = `${Api.api}billing/bill`;
}
