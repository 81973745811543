































import { Vue, Component, Prop } from 'vue-property-decorator';
import PaymentMethodButton from './PaymentMethodButton.vue';
import PaymentSelectorObject from '../objects/PaymentSelectorObject';
import ChevronLeft from '../assets/svg/chevron-left.svg';

@Component({
  components: {
    PaymentMethodButton,
    ChevronLeft,
  },
})
export default class PaymentSelector extends Vue {
    @Prop() private value!: string;

    @Prop() private paymentMethods!: Array<PaymentSelectorObject>;

    @Prop() private alternativePaymentMethods!: Array<PaymentSelectorObject>;

    private selected = '';

    private openAlternative = false;

    openAlternativeMenu() {
      if (this.alternativePaymentMethods.find((x) => x.name === this.selected)) return;
      this.openAlternative = !this.openAlternative;
    }

    created() {
      this.onClickEvent(this.paymentMethods[0].name);
    }

    private onClickEvent(value: string) {
      this.$emit('input', value);
      this.selected = value;
    }
}
