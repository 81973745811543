export default class Define {
    [key: string]: string | number; // Must accommodate all members

    [index: number]: string; // Can be a subset of string indexer

    public 'no-match' = 0;

    public small = 1;

    public medium = 2;

    public large = 3;

    public xlarge = 4;
}
