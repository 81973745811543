import ValidationObject from '../objects/ValidationObject';

export default function montoValidation(monto: number, payment: string) {
  const usdMonto = 5;
  const arsMonto = 500;
  const method = payment.toLowerCase();

  if (method === 'paypal' && Number(monto) < usdMonto) {
    return new ValidationObject(false, `Ingresá un monto de al menos USD ${usdMonto}`);
  }

  if ((method === 'mercadopago' || method === 'débito' || method === 'mobbex') && Number(monto) < arsMonto) {
    return new ValidationObject(false, `Ingresá un monto de al menos $${arsMonto}`);
  }

  return new ValidationObject(true, '');
}
