export default class CardObject {
  public img: string;

  public title: string;

  public text: string;

  public number: string;

  public badge: string;

  constructor(
    img: string,
    title: string,
    text: string,
    number: string,
    badge: string,
  ) {
    this.img = img;
    this.title = title;
    this.text = text;
    this.number = number;
    this.badge = badge;
  }
}
