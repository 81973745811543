










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
    @Prop() private action!: Function;

    @Prop() private type!: string;

    onClick() {
      this.action();
    }
}
