import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Redirect.vue'),
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Redirect.vue'),
  },
  {
    path: '/redirect/:external_reference',
    name: 'RedirectWithExternalReference',
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Redirect.vue'),
  },
  {
    path: '/ssolink',
    name: 'SsoLink',
    component: () => import(/* webpackChunkName: "ssolink" */ '../views/SsoLink.vue'),
  },
  {
    path: '/emailsent',
    name: 'EmailSent',
    component: () => import(/* webpackChunkName: "emailsent" */ '../views/Emailsent.vue'),
  },
  {
    path: '/mundobancante',
    name: 'MundoBancante',
    component: () => import(/* webpackChunkName: "mundobancante" */ '../views/Mundobancante.vue'),
  },
  {
    path: '/mundobancante/monto',
    name: 'Monto',
    component: () => import(/* webpackChunkName: "monto" */ '@/views/Monto.vue'),
  },
  {
    path: '/mundobancante/montocambiado',
    name: 'Montocambiado',
    component: () => import(/* webpackChunkName: "montocambiado" */ '@/views/Montocambiado.vue'),
  },
  {
    path: '/mundobancante/gifts',
    name: 'Gifts',
    component: () => import(/* webpackChunkName: "gifts" */ '@/views/Gifts.vue'),
  },
  {
    path: '/mundobancante/gifts/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '@/views/Checkout.vue'),
  },
  {
    path: '/mundobancante/userdata',
    name: 'Userdata',
    component: () => import(/* webpackChunkName: "userdata" */ '@/views/Userdata.vue'),
  },
  {
    path: '/gracias',
    name: 'Gracias',
    component: () => import(/* webpackChunkName: "gracias" */ '@/views/Gracias.vue'),
  },
  {
    path: '/mundobancante/baja',
    name: 'Baja',
    component: () => import(/* webpackChunkName: "baja" */ '@/views/Baja.vue'),
  },
  {
    path: '/mundobancante/bajacorrecta',
    name: 'Bajacorrecta',
    component: () => import(/* webpackChunkName: "bajacorrecta" */ '@/views/Bajacorrecta.vue'),
  },
  {
    path: '/mundobancante/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/Notifications.vue'),
  },
  {
    path: '/mundobancante/billing',
    name: 'Billing',
    component: () => import(/* webpackChunkName: "billing" */ '@/views/Billing.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function hasQueryParams(route: Route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name || '', query: from.query });
  } else {
    next();
  }
});

export default router;
