











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class RadioButtonIcon extends Vue {
    @Prop() private action!: Function;

    @Prop() private type!: string;

    @Prop() private icon!: string;

    @Prop() private active!: boolean;

    private publicPath = process.env.BASE_URL;

    onClick() {
      this.action();
    }
}
