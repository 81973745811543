












import { Component, Vue } from 'vue-property-decorator';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import TitleDecoration from './Text/TitleDecoration.vue';
import 'swiper/css/swiper.css';

@Component({
  components: {
    Swiper,
    SwiperSlide,
    TitleDecoration,
  },
})
export default class WordSlider extends Vue {
    private words: Array<string> = ['Más libros', 'Más conversaciónes', 'Más formatos', 'más largos,', 'más grandes,', 'más locos.'];

    $refs!: {
      mySwiper: any;
    }

    private delay = 2500;

    private swiperOption = {
      autoplay: {
        delay: this.delay,
        disableOnInteraction: false,
      },
      init: false,
    }

    private counter = 0;

    private lastTick = performance.now();

    get slider() {
      return this.$refs.mySwiper.$swiper;
    }

    get progress() {
      return Math.floor((this.counter / this.delay) * 100);
    }

    mounted() {
      this.slider.init();
      requestAnimationFrame(this.counterTick);
      this.slider.on('slideChange', () => {
        this.counter = 0;
      });
    }

    counterTick() {
      const now = performance.now();
      const delta = now - this.lastTick;
      this.lastTick = now;
      this.counter += delta;
      window.requestAnimationFrame(this.counterTick);
    }
}
