<template>
    <div ref="lottie"></div>
</template>

<script>
import bodymovin from 'lottie-web';

export default {
  props: ['animationData'],
  mounted() {
    const animData = {
      wrapper: this.$refs.lottie,
      loop: true,
      renderer: 'svg',
      autoplay: true,
      animationData: this.animationData,
    };
    this.$emit('created', bodymovin.loadAnimation(animData));
  },
};
</script>
