<script>
import IconIBrainYouYellow from '../assets/svg/icon-i-brain-you-yellow.svg';
import IconLogoYellow from '../assets/svg/icon-logo-yellow.svg';

export default {
  name: 'ShareSection',
  components: {
    IconIBrainYouYellow,
    IconLogoYellow,
  },
};
</script>

<template>
    <article id="share">
      <div class="container">
        <IconLogoYellow class="logo"/>
        <h1><i>Compartir</i> también es bancar</h1>
        <IconIBrainYouYellow class="i-brain-you"/>
        <div class="row">
          <div class="col-12 col-md-6 offset-md-3">
            <div class="row">
              <div class="col-10 offset-1 offset-md-0 col-md-6 pl-md-1">
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://elgatoylacaja.com/bancar/"
                  class="btn btn-default btn-block share"
                >
                  Compartí en facebook
                </a>
              </div>
              <div class="col-10 offset-1 offset-md-0 col-md-6 pr-md-1">
                <a
                  target="_blank"
                  href="https://twitter.com/intent/tweet?text=Sumate al grupo de personas que hace que El Gato y La Caja sea posible: un proyecto independiente, sin intermediarios y libre para todo el mundo.&url=https://elgatoylacaja.com/bancar/"
                  class="btn btn-default btn-block share"
                >
                  Compartí en twitter
                </a>
              </div>
              <div class="col-10 offset-1 d-block d-md-none">
                <a
                  href="whatsapp://send?text=Sumate al grupo de personas que hace que El Gato y La Caja sea posible: un proyecto independiente, sin intermediarios y libre para todo el mundo. https://elgatoylacaja.com/bancar/"
                  class="col btn btn-default btn-block share"
                >
                  Compartí en whatsapp
                </a>
              </div>
            </div>
          </div>
        </div>
        <span class="decoration">&lt;33345</span>
      </div>
    </article>
</template>

<style lang="scss" scoped>
.logo{
  top: 0;
  left: 0;
  position: absolute;
  display: none;

  @include md-up{
    display: block;
  }
}

.container{
  position: relative;
}

.decoration {
  font-family: 'EB Garamond', serif;
  font-style: italic;
  font-size: 14px;
  color: #F4EC4E;
  margin-top: 50px;
  text-align: center;
  display: block;

  @include md-up{
    margin-top: 0;
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.i-brain-you {
  position: absolute;
  top: 38px;
  left: 55%;

  @include md-up{
    top: 54px;
  }
}

#share {
  background: #000;
  padding: 50px 0;
  position: relative;
}

#share h1 {
  font-family: 'EB Garamond', serif;
  font-size: 42px;
  text-align: center;
  color: #FFFFFF;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 30px;

  @include md-up{
    font-size: 64px;
    max-width: 450px;
  }
}

#share p {
  font-size: 5.5vw;
  text-align: center;
}

#share .btn.btn-default.share {
  background: transparent;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  font-weight: 900;
  border-radius: 0;
  margin-top: 10px;
  border: 1px solid #fff;
}

#share .btn.btn-default.share:hover {
  background: #fff;
  color: #000;
}

#share .btn.btn-default.share i {
  font-size: 23px;
  margin-left: 5px;
}

.share-buttons {
  margin-top: 30px;
}

@include sm-up{
  #share p {
    font-size: 22px;
  }
}
</style>
