import { Model } from 'vue-breakpoint-component';
import Define from './Define';

export default class BreakpointsHelper {
  model = new Model();

  define = new Define();

  noMatch = 0;

  small = 1;

  medium = 2;

  large = 3;

  xlarge = 4;

  get breakpoint() {
    const bp: string = this.model.breakpoint;
    return this.define[bp];
  }
}
