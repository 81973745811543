import './utils/publicpath';
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    accessToken: null,
    username: '',
    email: '',
    override: '',
    fetched: false,
    variables: {
      ENVIRONMENT: 'production',
      AUTH_APP_URL: 'https://auth.elgatoylacaja.com',
      FORM_REDIRECT_URL: 'https://elgatoylacaja.com/bancar/redirect/$1',
    },
  },
  mutations: {
    addAccessToken(state, { accessToken, username, email }) {
      state.accessToken = accessToken;
      state.username = username;
      state.email = email;
      state.fetched = true;
    },
    setFetched(state, fetched = true) {
      state.fetched = fetched;
    },
    setOverride(state, email) {
      state.override = email;
    },
  },
});

new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount('#app');
