




import { Vue, Component, Prop } from 'vue-property-decorator';
import CatObject from '../objects/CatObject';

@Component
export default class Cat extends Vue {
    @Prop() private catData!: CatObject;

    private svgString = '';

    created() {
      this.svgString = this.generate();
    }

    generate() {
      return `<svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1083 1080" xml:space="preserve">
                <g id="Capa_1">
                  <rect fill="${this.catData.background}" width="1083" height="1080"/>
                </g>
                ${this.catData.getCat()}
              </svg>`;
    }
}
