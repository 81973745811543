export default class ValidationObject {
    public status: boolean;

    public message: string;

    constructor(
      status: boolean,
      message: string,
    ) {
      this.status = status;
      this.message = message;
    }
}
