
















import { Vue, Component, Prop } from 'vue-property-decorator';
import PaymentOptionButton from './PaymentOptionButton.vue';
import PaymentOptionObject from '../objects/PaymentOptionObject';

@Component({
  components: {
    PaymentOptionButton,
  },
})
export default class PaymentOptionSelector extends Vue {
    @Prop() private value!: string;

    @Prop() private paymentValues!: Array<PaymentOptionObject>;

    private selected = '';

    mounted() {
      this.onClickEvent(this.paymentValues[1].value);
    }

    private onClickEvent(value: string) {
      this.$emit('input', value);
      this.selected = value;
    }
}
