<script>
export default {
  name: 'modal',
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<template>
    <div class="cont">
      <div class="modal-backdrop" @click="close"></div>
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
          <slot name="header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            x
          </button>
        </header>
        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            I'm the default body!
          </slot>
        </section>
      </div>
    </div>
</template>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    min-height: 300px;
    max-height: 94vh;
    height: initial;
    margin-top: 5vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #68BBE8;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    overflow-y:auto;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #68BBE8;
    background: transparent;
  }
</style>
<style scoped lang="scss">
  @include md-up {
    .modal{
      max-height: 82vh;
    }
    .modalCat {
      padding: 20px 30%;
    }
  }
</style>
