



























import { Component, Vue } from 'vue-property-decorator';
/* eslint-disable-next-line */
// @ts-ignore
import { VuePrlxDirective } from 'vue-prlx';
import TextMedium from './Text/TextMedium.vue';
import TitleDisplay from './Text/TitleDisplay.vue';
import LabelDecorativo from './Text/LabelDecorativo.vue';
import PorQueIcon from '../assets/svg/por-que-icon.svg';
import IconCuadrado from '../assets/svg/icon-cuadrado.svg';

@Component({
  components: {
    TitleDisplay,
    TextMedium,
    LabelDecorativo,
    PorQueIcon,
    IconCuadrado,
  },
  directives: {
    prlx: VuePrlxDirective,
  },
})
export default class Whoweare extends Vue {
}
