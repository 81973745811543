<script>
export default {
  name: 'FooterSection',
};
</script>

<template>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-4"></div>
          <div class="col-4">
            <svg
              width="30"
              baseProfile="tiny"
              version="1.2"
              viewBox="0 0 35.1 72.9"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m17.7 29.4c-0.3 0-0.5-0.1-0.8-0.2l-10.2-5.8c-0.5-0.3-0.8-0.8-0.8-1.4v-11.8c0-0.6 0.3-1.1 0.8-1.4l10.2-5.8c0.5-0.3 1.1-0.3 1.6 0l10.2 5.9c0.5 0.3 0.8 0.8 0.8 1.4v11.7c0 0.6-0.3 1.1-0.8 1.4l-10.2 5.9c-0.3 0.1-0.6 0.1-0.8 0.1zm-8.6-8.4l8.6 5 8.6-5v-9.9l-8.6-5-8.6 5v9.9z"
                fill="#14273C"
              />
              <path
                d="m17.7 72.5c-0.3 0-0.6-0.1-0.9-0.2l-15.3-8.8c-0.6-0.3-0.9-0.9-0.9-1.5v-17.7c0-0.6 0.3-1.2 0.9-1.5l6.9-4c0.9-0.5 1.9-0.2 2.4 0.6s0.2 1.9-0.6 2.4l-6 3.5v15.7l13.6 7.8 13.6-7.8v-15.7l-20.7-12c-0.7-0.3-1.1-0.9-1.1-1.5v-6.3c0-1 0.8-1.8 1.8-1.8s1.8 0.8 1.8 1.8v5.2l20.7 12c0.6 0.3 0.9 0.9 0.9 1.5v17.7c0 0.6-0.3 1.2-0.9 1.5l-15.3 8.8c-0.3 0.3-0.6 0.3-0.9 0.3z"
                fill="#14273C"
              />
              <polygon points="7.3 6.2 11.8 3.6 7.3 0.3" fill="#14273C" />
              <polygon points="28.6 6.2 24.1 3.6 28.6 0.3" fill="#14273C" />
            </svg>
          </div>
          <div class="col-4">
          </div>
        </div>
      </div>
    </footer>
</template>

<style scoped>
footer {
  padding-top: 20px;
  padding-bottom: 40px;
}

footer svg {
  display: block;
  margin: 0 auto;
}

footer .btn.btn-default.footer {
  float: right;
  color: #ef5c4e;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-transform: uppercase;
}
</style>
