




import { Vue, Component, Prop } from 'vue-property-decorator';
import TextSmall from './Text/TextSmall.vue';

@Component({
  components: {
    TextSmall,
  },
})
export default class CheckItem extends Vue {
  @Prop() private text!: string;

  @Prop() private type!: string;
}
