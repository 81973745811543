export default class PaymentOptionObject {
  public name: string;

  public value: string;

  public icon?: string;

  constructor(
    name: string,
    value: string,
    icon?: string,
  ) {
    this.name = name;
    this.value = value;
    this.icon = icon;
  }
}
