






import { Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import VueExtension from './VueExtension';

@Component({
  computed: mapState(['fetched', 'accessToken']),
})
export default class App extends VueExtension {
  public fetched?: boolean;

  public accessToken?: string;

  @Watch('fetched')
  watchFetched(value: boolean) {
    if (value && !this.accessToken) {
      if (this.$route.fullPath.indexOf('mundobancante') !== -1) {
        this.$router.push({ name: 'Redirect' });
      }
    }
  }

  created() {
    this.authenticate();
  }

  authenticate() {
    const queryParams = new URLSearchParams(window.location.search);
    const queryAccessToken = queryParams.get('accessToken') || '';
    const queryOverride = queryParams.get('override');

    if (queryOverride !== null) {
      localStorage.setItem('mundobancante-override', queryOverride);
    }

    if (queryAccessToken) {
      this.checkAndLogin(queryAccessToken);
    } else {
      this.refreshTokenLogin();
    }
  }

  async checkAndLogin(accessToken: string) {
    try {
      const data = await fetch(`${this.$store.state.variables.AUTH_APP_URL}/api/user/validateToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: accessToken }),
      });

      const res = await data.json();
      const username = res.data.username || null;
      const email = res.data.email || null;
      this.login(accessToken, username, email);
    } catch (e) {
      console.log('Error validating token. might be outdated. Refreshing token');
      this.refreshTokenLogin();
    }
  }

  async refreshTokenLogin() {
    const data = await fetch(`${this.$store.state.variables.AUTH_APP_URL}/api/user/refreshToken`, {
      credentials: 'include',
    });
    const res = await data.json();
    const { data: { accessToken } } = res;

    if (accessToken) {
      this.checkAndLogin(accessToken);
    } else {
      this.$store.commit('setFetched');
    }
  }

  login(accessToken: string, username: string, email: string) {
    this.$store.commit('addAccessToken', { accessToken, username, email });
  }
}
