export default class PaymentSelectorObject {
  public name: string;

  public icon: string;

  constructor(
    name: string,
    icon: string,
  ) {
    this.name = name;
    this.icon = icon;
  }
}
