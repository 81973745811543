
















import { Component, Vue } from 'vue-property-decorator';
import Header from '../components/Header.vue';
import Whoweare from '../components/Whoweare.vue';
import WhyPay from '../components/WhyPay.vue';
import Form from '../components/Form.vue';
import BancanteWorld from '../components/BancanteWorld.vue';
import CatDisplayer from '../components/CatDisplayer.vue';
import FooterSection from '../components/FooterSection.vue';
import ShareSection from '../components/ShareSection.vue';
import Testimonials from '../components/Testimonials.vue';

@Component({
  components: {
    Header,
    Whoweare,
    WhyPay,
    BancanteWorld,
    Form,
    CatDisplayer,
    FooterSection,
    ShareSection,
    Testimonials,
  },
})
export default class Home extends Vue {
  private observer!: IntersectionObserver;

  $refs!: {
    header: Header;
  };

  mounted() {
    this.activateIntersectionObserver();
  }

  activateIntersectionObserver() {
    setTimeout(() => {
      if (window.IntersectionObserver && !this.observer) {
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.stepEnterHandler(entry.target);
            }
          });
        }, { rootMargin: '0px 0px -90% 0px' });
        const observerBlocks = document.getElementsByClassName('block');
        if (observerBlocks) [...observerBlocks].forEach((div) => { if (div) this.observer.observe(div); });
      }
    }, 500);
  }

  stepEnterHandler = (target: Element) => {
    const attr = target.attributes.getNamedItem('data-step')?.value;

    switch (attr) {
      case 'a':
        this.$refs.header.unpauseCat();
        break;

      case 'b':
        this.$refs.header.unpauseCat();
        break;

      case 'c':
        this.$refs.header.pauseCat();
        break;

      default:
        break;
    }
  }
}
