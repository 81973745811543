



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class InputText extends Vue {
    @Prop() private label!: string;

    @Prop() private required!: string;

    @Prop() private value!: string;

    @Prop() private name!: string;

    @Prop() private type!: string;

    @Prop() private readonly!: boolean;

    private focus = false;

    update() {
      this.$emit('input', (this.$refs.txt as Vue & { value: () => string }).value);
    }
}
