




import { Vue, Component } from 'vue-property-decorator';

@Component
class TextMedium extends Vue {

}

export default TextMedium;
