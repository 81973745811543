import { render, staticRenderFns } from "./FooterSection.vue?vue&type=template&id=61b3e348&scoped=true&"
import script from "./FooterSection.vue?vue&type=script&lang=js&"
export * from "./FooterSection.vue?vue&type=script&lang=js&"
import style0 from "./FooterSection.vue?vue&type=style&index=0&id=61b3e348&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b3e348",
  null
  
)

export default component.exports