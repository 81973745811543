



















































import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
/* eslint-disable-next-line */
// @ts-ignore
import { VuePrlxDirective } from 'vue-prlx';
import { Component, Vue } from 'vue-property-decorator';
import { VBreakpoint } from 'vue-breakpoint-component';
import BreakpointsHelper from '../helpers/BreakpointsHelper';
import Lottie from './Lottie.vue';
import animationData from '../assets/lottie/badgewhite.json';
import TitleMainDisplay from './Text/TitleMainDisplay.vue';
import TitleDecoration from './Text/TitleDecoration.vue';
import Card from './Card.vue';
import CardObject from '../objects/CardObject';
import Img02 from '../assets/svg/img02.svg';
import IconFlechaLeft from '../assets/svg/icon-flecha-left.svg';
import IconFlechaRight from '../assets/svg/icon-flecha-right.svg';

// Import Swiper styles
import 'swiper/css/swiper.css';

@Component({
  components: {
    TitleMainDisplay,
    Card,
    Lottie,
    Swiper,
    SwiperSlide,
    TitleDecoration,
    Img02,
    IconFlechaLeft,
    IconFlechaRight,
    VBreakpoint,
  },
  directives: {
    prlx: VuePrlxDirective,
  },
})
export default class WhyPay extends Vue {
  private animationData = animationData;

  private BH = new BreakpointsHelper();

  $refs!: {
    mySwiper: any;
  }

  private isMobile = window.innerWidth < 768;

  initialSlideNumber = () => {
    if (window.innerWidth < 768) return 0;
    if (window.innerWidth < 1600) return 1;
    return 2;
  }

  private swiperOption = {
    slidesPerView: 1.5,
    initialSlide: this.initialSlideNumber(),
    loop: true,
    centeredSlides: !this.isMobile,
    spaceBetween: !this.isMobile ? 30 : 0,
    grabCursor: true,
    breakpoints: {
      1800: {
        slidesPerView: 5.5,
      },
      1600: {
        slidesPerView: 4.8,
      },
      768: {
        slidesPerView: 3.5,
      },
    },
  };

  get swiper() {
    return this.$refs.mySwiper.$swiper;
  }

  slide(dir: number) {
    if (dir) this.swiper.slideNext();
    else this.swiper.slidePrev();
  }

  private cards: Array<CardObject> = [
    new CardObject(
      'badge-clima.svg',
      'Clima. El desafío de diseño más grande de todos los tiempos',
      '+560 comentarios en proceso abierto <br><br> 5 equipos <br> 18 autores',
      '2022',
      'badge',
    ),
    new CardObject(
      'badge-ohla.png',
      'Oh, la humanidad',
      '2 <br>temporadas <br><br> +93k veces escuchado',
      '2021',
      'badge',
    ),
    new CardObject(
      'badge-podcast.png',
      'Breve podcast de la pandemia',
      '+100 episodios <br><br> +460k veces escuchado',
      '2020',
      'badge',
    ),
    new CardObject(
      'badge-118.png',
      'Tabla. Un gran proceso abierto',
      '118 <br>historias <br><br> +100 autores y autoras',
      '2019',
      'badge',
    ),
    new CardObject(
      'badge-pco.png',
      'Llenamos el Congreso de libros',
      '329 legisladores <br><br> 1 proyecto de ley',
      '2018',
      'badge',
    ),
    new CardObject(
      'badge-ulsd.png',
      'Un libro para avisar que la política de drogas es muy falopa',
      '+10k libros impresos <br><br> +800k hits en digital',
      '2017',
      'badge',
    ),
    new CardObject(
      'badge-labs.png',
      'Creamos un laboratorio en internet',
      '+10 experimentos <br><br> +310k de respuestas',
      '2016',
      'badge',
    ),
  ];

  private publicPath = process.env.BASE_URL;

  private animation!: any;

  onAnimationCreated(animation: any) {
    this.animation = animation;
  }
}
