







import { Vue, Component, Prop } from 'vue-property-decorator';
import Cat from './Cat.vue';
import CatObject from '../objects/CatObject';

@Component({
  components: {
    Cat,
  },
})
export default class CatButton extends Vue {
    @Prop() private catData!: CatObject;

    onClick() {
      this.$emit('buttonClick', this.catData);
    }
}
